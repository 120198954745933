@import "global.scss";
.timezoneInput {
  display: flex;
  flex-direction: row;

  & > * {
    width: 100%;
    margin: 0 1em 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

@import "global.scss";
.fields {
  width: 100%;
  margin: 1em auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > div {
    width: 100%;
    padding-right: 1em;

    &:last-child {
      padding-right: 0;
    }
  }
}

@import "global.scss";
.navbarSubItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  //padding: 0 .5em 0 1em;

  &:hover {
    background-color: $highlight;
  }

  .label {
    font-size: .95em;
    padding: .5em 0 .5em 1.5rem;
  }
}

.activeItem {
  background-color: $highlight;
  //color: $primary;

  .navbarSubItem:hover {
     background-color: #e0e0e0 !important;
  }

  p {
    font-weight: 500;
  }
}

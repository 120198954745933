@import "global.scss";
.changelog {
  width: 90%;
  min-width: 420px;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 0 8em 0;

  img {
    display: block;
    width: 50%;
    max-width: 20rem;
    height: auto;
    margin: 4em auto 6em auto;
  }

  .topHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 700;
      font-size: 2em;
      margin: 0 0 1em 0;
      flex-basis: 65%;
    }

    .lastUpdated {
      flex-basis: 35%;
      text-align: right;
      margin-bottom: 2rem;
      font-style: italic;
      //background-color: $highlight;
    }
  }
}

@import "global.scss";
//foreignObject {
//  path {
//    stroke: none !important;
//  }
//}

.svgOverride {
  & > g > path {
    stroke: #aaaaaa !important;
  }
}

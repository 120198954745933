@import "global.scss";
.requestPasswordReset {
  width: 20em;
  margin: 2em auto;
  padding: 2em;
  border: $border;
  border-radius: $border-radius;

  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.5em;
    color: $text-primary;
  }


  .description {
    line-height: 1.3;
    margin-bottom: 2em;

    a {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .horizontalInputs {
    display: flex;
    flex-direction: row;

    & > * {
      width: 100%;
      margin: 0 1em 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .formControls {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .alert {
    margin-top: 1em;
  }
}

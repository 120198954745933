@import "global.scss";
.scoreDisplay {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.grow) {
    height: 3rem;
    width: 3rem;
  }
}

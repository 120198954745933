@import "global.scss";
.instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    display: block;
    width: 50%;
    max-width: 20rem;
    height: auto;
    margin: 4em auto 6em auto;
  }

  .topHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 700;
      font-size: 2em;
      margin: 0 0 1em 0;
      flex-basis: 65%;
    }

    .lastUpdated {
      flex-basis: 35%;
      text-align: right;
      margin-bottom: 2rem;
      font-style: italic;
      //background-color: $highlight;
    }
  }

  .important {
    font-weight: 500;
  }
}


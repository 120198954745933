@import "global.scss";
.form {
  position: relative;
  height: 0;
}


.treeEditorWrapper {
  border: $border;
  border-radius: $border-radius;
  //width: 180%;
  //position: relative;
  //left: -40%;
  height: 100%;
}

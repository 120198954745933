@import "global.scss";
.preparedCommitment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: .5rem;
  border: $border;
  border-radius: .5rem;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #ffffff;
  color: $text-primary;
}



.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.stack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: .5rem;
}

.label {
  font-size: 1rem;
  font-weight: 500;
}

.note {
  font-size: .9rem;
}

.valueRange {
  color: $text-primary-lighter;
  font-size: 1rem;
  font-weight: 500;
  margin-top: -.4rem;

  .icon {
    font-size: 1.2rem;
    margin: 0 .25rem;

    & > svg {
      position: relative;
      top: .2rem;
    }
  }
}

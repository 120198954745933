@import "global.scss";
.navbarItem{
  display: flex;
  flex-direction: column;

  &.activeItem {
    .link {
      background-color: $highlight;

      &:hover {
        background-color: #e0e0e0 !important;
      }
    }
  }

  .link {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1em;
    box-sizing: border-box;

    &:hover {
      background-color: $highlight;
    }

    .icon {
      color: $text-primary;
      margin-right: 1em;
    }

    .label {
      padding-bottom: .2em;
    }
  }

  .subItems {
    display: flex;
    flex-direction: column;
  }
}

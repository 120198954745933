@import "global.scss";
.connectedUsersIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  margin-right: 1.2rem; // Offset to account for overlapping portraits (portrait width + gap - max width)

  .portraitWrapper {
    max-width: 1.3rem;
    overflow: visible;
  }
}

@import "global.scss";
.userAttendanceForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;

  .name {
    min-width: 14rem;
    flex-basis: 30%;
  }

  .select {
    flex-basis: 70%;
    min-width: 17rem;
  }

}


@import "global.scss";
.team {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 2rem;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    color: $text-primary
  }

  .cards {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 1.5rem;

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 3rem;
      gap: 1.5rem;
      border: $border;
      border-radius: $border-radius;
      flex-basis: 50%;

      .stack {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        gap: .5rem;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        gap: 3rem;
      }

      .score {
        height: 6rem;
        width: 6rem;
      }

      .cardContent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1rem;
        box-sizing: border-box;
        gap: 1.5rem;
        flex-grow: 1;
      }

      h1 {
        font-size: 3rem;
        text-align: center;
      }

      h2 {
        font-weight: 400;
        font-size: 1.25rem;
        color: $text-primary;
        text-align: center;
      }

      h3 {
        font-weight: 400;
        font-size: 1rem;
        color: $text-primary-lighter;
        text-align: center;
      }
    }
  }
}


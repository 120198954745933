@import "global.scss";
.formGoalNode {
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.isTeamGoal {

  }

  path {
    stroke: none;
  }

  .topRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .teamGoalIndicator {
      color: $primary;
      font-size: .85rem;
      font-weight: 500;
      padding: .2rem .5rem;
      background-color: #eef6ff;
      border-radius: .425rem;
      border: 1px solid $primary;
      box-sizing: border-box;
      white-space: nowrap;
      margin-right: .5rem;
    }
  }

  .innerWrapper {
    padding: .75rem .5rem .5rem .5rem;
    box-sizing: border-box;
    border: $border;
    border-radius: $border-radius;
    background-color: #f9f9f9;
    overflow: hidden;

    .innerWrapper {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1em;
    }
  }
}

@import "global.scss";
.groupNode {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .innerWrapper {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    border-radius: $border-radius;
    border: $border;

    .icon {
      font-size: 1.15em;
      color: color(from $text-primary-darker srgb r g b / 0.5);
      stroke: none;
    }

    h2 {
      font-weight: 500;
      font-size: 1.25rem;
      color: $text-primary;
    }
  }
}

@import "global.scss";
.goalEntry {
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //
  //path {
  //  stroke: none;
  //}
  .innerWrapper {
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    border: $border;
    border-radius: $border-radius;
    background: #f9f9f9;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .topRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: .5rem .75rem;
      box-sizing: border-box;
      border-bottom: $border;

      .title {
        color: $text-primary-darker;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
      }
    }

    .mainRow {
      width: 100%;
      box-sizing: border-box;
      padding: .5rem .75rem;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;

      .stack {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &:nth-child(1) {
          box-sizing: border-box;
          padding-right: 2em;
          flex-basis: 20%;

          .description {
            font-size: .9em;
            color: $text-primary-lighter;
          }

        }

        &:nth-child(2) {
          box-sizing: border-box;
          padding-right: 2em;
          flex-basis: 20%;

          .stack:first-of-type {
            padding-bottom: 1rem;
          }

          .value {
            font-size: .85rem;
            color: $text-primary-lighter;
          }
        }

        &:nth-child(3) {
          box-sizing: border-box;
          padding-right: 2em;
          flex-basis: 30%;
          height: auto;
        }

        &:nth-child(4) {
          box-sizing: border-box;
          flex-basis: 30%;

          .label:not(:first-child) {
            padding-top: .5rem;
          }

          .teams {
            color: $primary-lighter;
            font-size: .9em;
            font-weight: 500;

            .teamName {
              color: $primary-lighter;
              font-weight: 500;
            }

            .andMore {
              font-weight: 300;
              font-size: .9em;
            }
          }

          .value {
            color: $text-primary;
            font-weight: 500;
            padding-right: .5em;
          }

          .valueSecondary{
            padding-left: .5em;
            font-weight: 300;
          }

          .row:has(.teams) button {
            margin-top: -.5rem;
          }
        }

        & > * {
          width: 100%;
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-bottom: .5rem;

          &:last-child {
            padding-bottom: 0;
          }
        }

        .row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          padding: 0;
        }

        .label  {
          color: $text-primary;
          font-size: .85rem;
          font-weight: 500;
        }

        .value {
          font-size: .9rem;
          color: $text-primary;
        }

        .valueSecondary {
          font-size: .9rem;
          color: $text-primary-lighter;
        }

        button {
          margin-top: auto;
        }

        .noData {
          font-size: .9rem;
          color: $text-primary-lighter;
          font-style: italic;
        }
      }
    }
  }
}

@import "global.scss";
.updateDialog {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  box-sizing: border-box;

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    h1 {
      display: block;
      font-weight: 500;
      font-size: 2.5rem;
      margin: 3rem 1.5rem 1.5rem 2rem;
      flex-grow: 1;
    }

    button {
      margin: .5rem;
    }
  }

  .content {
    padding: 1.5rem 2rem 3rem 2rem;
  }


}

@import "global.scss";
.portrait {
  .innerWrapper {
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    border-width: .2rem;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 .3rem #aaa;

    p {
      font-size: .9rem;
      line-height: 1;
      font-weight: 700;
      color: #999;
      //overflow: hidden;
    }
  }

  .connectionIndicator {
    content: '';
    display: block;
    width: .65rem;
    height: .65rem;
    border-radius: 50%;
    position: relative;
    left: 1.75rem;
    bottom: .15rem;
    background-color: #39b750;
    margin-top: -.5rem;
    border: .15rem solid white;
    box-sizing: border-box;
  }
}

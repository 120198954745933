@import "global.scss";
.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  //max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.columnWrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.leftColumn {
  display: flex;
  flex-direction: column;
}

.navbarWrapper {
  flex-grow: 1;
  border-right: $border;
}

.navbarWrapperInner {
  display: flex;
  position: sticky;
  top: 4em;
  height: calc(100vh - 4rem);
}


.rightColumn {
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  overflow-y: scroll;
}

.forceFullWidth{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.view {
  width: 90%;
  max-width: 1200px;
  margin: 3em auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  &:has(.forceFullWidth) {
    max-width: unset;
    width: 95%;
  }
}

@import "global.scss";
.title {
  color: $text-primary-darker;
}

.content {
  padding: 0 1.5rem 1rem 1.5rem;

  .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1.5rem;
  }
}

@import "global.scss";
.version {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 4rem;
  border: $border;
  border-radius: .5rem;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: $border;
    background: #f0f0f0;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      .semanticVersion {
        font-size: 1.25rem;
        font-weight: 600;
      }

      .updateType {
        font-size: .9rem;
        text-transform: capitalize;
        padding: .25rem .75rem;
        border-radius: .5rem;
        font-weight: 500;

        &.hotfix {
          background-color: #ffe0e0;
          color: #791111;
          border: 1px solid #dc7e7e;
        }

        &.minor {
          background-color: #dcfffa;
          color: #22655f;
          border: 1px solid #499b94;
        }

        &.major {
          background-color: #e2ffdc;
          color: #055b00;
          border: 1px solid #1e9f16;
        }

        &.patch {
          background-color: #ffebcc;
          color: #7a5000;
          border: 1px solid #be8d30;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;

      .relativeDate {
        font-size: 1rem;
        font-weight: 400;
        color: $text-primary;
      }

      .absoluteDate {
        font-size: .9rem;
        color: $text-primary-lighter;
      }
    }
  }

  .content {
    padding: 1.5rem 1rem;

    h1, h2, p, a, img {
      margin: .5rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      font-size: 1rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      &:after {
        margin-left: 1rem;
        content: '';
        display: block;
        height: 2px;
        flex-grow: 1;
      }

      &:nth-of-type(n) {
        color: #327b1b;
        &:after {
          background-color: #57a23f;
        }
      }

      //&:nth-of-type(5n-4) {
      //  color: #327b1b;
      //  &:after {
      //    background-color: #57a23f;
      //  }
      //}
      //
      //&:nth-of-type(5n-3) {
      //  color: #cb4343;
      //  &:after {
      //    background-color: #e04646;
      //  }
      //}
      //
      //&:nth-of-type(5n-2) {
      //  color: #d28700;
      //  &:after {
      //    background-color: #dc9e46;
      //  }
      //}
      //
      //&:nth-of-type(5n-1) {
      //  color: #02689b;
      //  &:after {
      //    background-color: #2f85b7;
      //  }
      //}
      //
      //&:nth-of-type(5n) {
      //  color: #6013a8;
      //  &:after {
      //    background-color: #8045c5;
      //  }
      //}
    }

    h2 {
      font-size: 1rem;
      margin-top: 1.25rem;
      margin-bottom: .75rem;
      font-weight: 400;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    img {
      display: block;
      margin: 2rem auto;
      width: 80%;
      max-width: 100%;
    }

    a {
      color: #317c1b;
      font-weight: 500;
    }
  }
}

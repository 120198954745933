@import "global.scss";
.title {
  color: $text-primary-darker;
}

.content {
  padding: 0 1.5rem 1rem 1.5rem;

  .form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    padding-top: 1rem;

    .formControl {
      width: 100%;
      margin: 0;
    }

    .input {
      width: 100%;
      background-color: white;
    }
  }

  .pastMeasures {
    padding: .5em 0;

    .label {
      color: $text-primary-darker;
      font-weight: 500;
      padding-bottom: .5em;
    }

    .measures {
      padding: 0 .25em;
      display: grid;
      grid-template-columns: max-content max-content max-content;
      gap: .5rem 1rem;

      p:nth-child(3n-2) {
        grid-column: 1 / 2;
      }

      p:nth-child(3n-1) {
        grid-column: 2 / 3;
      }

      p:nth-child(3n) {
        grid-column: 3 / 4;
        color: $text-primary-lighter;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1.5rem;
  }
}

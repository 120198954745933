@import "global.scss";
.error {
  text-align: center;
  width: 40em;
  margin: 1em auto;

  .icon {
    color: #bbb;
    font-size: 5em;
  }

  h2 {
    color: $text-primary-lighter;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 1em;
  }

  p {
    font-size: 1.1em;
    color: $text-primary;
  }
}

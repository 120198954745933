@import "global.scss";
.commitmentForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: .5rem;
  border: $border;
  border-radius: .5rem;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #ffffff;
  color: $text-primary;

  input {
    //color: $text-primary;
  }

  & > div {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }

  & > div > div:nth-child(1) {
    flex-grow: 1;
  }

  //& > div:nth-child(2) {
  //  flex-basis: 35%;
  //}
  //
  //& > div:nth-child(2) {
  //  flex-basis: 30%;
  //}

  .stack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
  }

  .rightStack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .checkboxRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    .menu {
      margin-right: -1em;
      margin-top: -1em;
    }
  }

  .contentRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    gap: 1rem;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }

  .icon {
    color: $text-primary-lighter;
    font-size: 1.2rem;
  }

  .select {
    //width: 25.5em;
  }

  :global(.MuiFormControlLabel-label) {
    font-size: .85rem;
  }
}

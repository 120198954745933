@import "global.scss";
.instructionsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  margin-bottom: 4em;
  padding: 1em 0;


  .anchor {
    width: 1px;
    height: 0px;
    position: relative;
    bottom: 6rem;
  }


  h1 {
    font-size: 1.5em;
    color: $text-primary;
    border-bottom: $border;
    padding-bottom: .5em;
    margin-bottom: 1.5em;
  }

  p {
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 1em;
    color: $text-primary-darker;
  }

  .sectionContent {
    padding: 0 2em;
    box-sizing: border-box;
  }
}


@media print {
  .instructionsSection {
    break-inside: avoid;
  }
}

@import "global.scss";
.goals {
  .header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 1.5rem;

    h1 {
      color: $text-primary;
      font-size: 1.5em;
      font-weight: 500;
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;

      .select {
        & > div {
          padding-top: .45rem;
          padding-bottom: .45rem;
        }
      }
    }
  }
}

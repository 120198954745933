@import "global.scss";
.editGoalHierarchyForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, a, span, button, input, label, th, td, li {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 1em;
  margin: 0;
  padding: 0;
}

ul {
  padding-left: 1rem;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: unset;
  background-color: unset;
  box-shadow: unset;
  cursor: pointer;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

::placeholder,
:-ms-input-placeholder,
::-webkit-input-placeholder,
:-moz-placeholder {
  color: rgba(175, 175, 175, 1);
  opacity: 1;
}

@import "global.scss";
.privacy {
  width: 90%;
  min-width: 420px;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 0 8em 0;

  img {
    display: block;
    width: 50%;
    max-width: 20rem;
    height: auto;
    margin: 4em auto 6em auto;
  }

  .topHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-weight: 700;
      font-size: 2em;
      margin: 0 0 1em 0;
      flex-basis: 65%;
    }

    .lastUpdated {
      flex-basis: 35%;
      text-align: right;
      margin-bottom: 2rem;
      font-style: italic;
      //background-color: $highlight;
    }
  }

  .keyPoints {
    padding: 1em;
    background-color: $highlight;
    margin: 2rem 0;

    h2 {
      margin-top: .5rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 1.6em;
    margin: 1.5em 0 1em 0;
  }

  h3 {
    font-weight: 500;
    font-size: 1.2em;
    margin: 1em 0 .5em 0;
  }

  h4 {
    font-weight: 500;
    font-size: 1.1em;
    margin: 1em 0 .5em 0;
  }

  ul {
    list-style-type: square;

    li {
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-weight: 300;
      line-height: 1.5;
    }
  }

  p {
    margin: .5em 0;
    line-height: 1.5;
  }

  a {
    color: #468053;
    font-weight: 500;
  }
}

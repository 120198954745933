@import "global.scss";
.nextMeetingBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid #a3a3a3;
  background-color: #f9f9f9;
  color: #505050;
  padding: 1rem;
  border-radius: .5rem;
  margin-bottom: 1rem;

  .stack {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  &.scheduled {
  }

  &.upcoming {
    color: #12415b;
    border-color: #3791c4;
    background-color: #f0f8ff;

    & .button {
      color: #1b5270;
    }
  }

  &.inProgress {
    color: #204f11;
    border-color: #57a23f;
    background-color: #f2fff2;

    & .button {
      color: #32731e;
    }
  }

  &.overdue {
    color: #794c19;
    border-color: #c47f30;
    background-color: #fff7f0;

    & .button {
      color: #8a5d2a;
    }
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 400;
  }

  p {
    font-weight: 500;
    line-height: 1.5;
  }
}

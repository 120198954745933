@import "global.scss";
.goalProgressChart {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  //.chart {
  //  :global(.recharts-cartesian-grid-horizontal) line {
  //    &:nth-last-child(1),
  //    &:nth-last-child(2) {
  //      display: none;
  //    }
  //  }
  //}
  .chart {
    .unclipPath path{
      clip-path: none;
    }

    .noData {
      font-size: 1.75em;
      fill: #cccccc;
    }
  }


  text {
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: .9em;
  }
}

@import "global.scss";
.prepareCommitments {
  //width: 30em;
  margin: 2em auto;
  padding: 2em;
  border: $border;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 1rem;

  h1 {
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    color: $text-primary;
  }

  h2 {
    width: 100%;
    font-size: 1.25em;
    color: $text-primary;
    //font-weight: 500;
  }


  .description {
    line-height: 1.3;
    padding: 1rem;

    a {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .formControls {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .loadingWrapper {
    padding: 4rem 0 1rem 0;
  }

  .commitments {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  .alert {
    margin-top: 1em;
  }
}

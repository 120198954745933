@import "global.scss";
.teamGoalNode {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &.teamGoal {
    .innerWrapper {
      .row.top {
        background-color: #e6effa
      }
    }
  }

  .innerWrapper {
    padding: 0 0 .3rem 0;
    box-sizing: border-box;
    width: 100%;
    border: $border;
    border-radius: $border-radius;
    background: #f9f9f9;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: .3rem;

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 .75rem;
      box-sizing: border-box;

      &.top {
        border-bottom: $border;
      }

      & > p {
        padding-top: .2rem;
        padding-bottom: .2rem;
      }
    }

    a {
      width: 100%;
    }

    .title {
      color: $text-primary-darker;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: .3rem 0;
    }

    .icon {
      margin-left: .2em;
      margin-right: -.1em;
      align-items: center;
      justify-content: center;
      font-size: 1.15em;
      height: 1em;
      color: color(from $text-primary-darker srgb r g b / 0.5);
      stroke: none;
    }

    .teams {
      color: $primary-lighter;
      font-size: .9em;

      .teamName {
        font-weight: 500;
        margin-right: .5em;
      }

      .andMore {
        font-weight: 300;
        font-size: .9em;
      }
    }

    .teamGoalIndicator {
      color: $primary;
      font-size: .75rem;
      font-weight: 500;
      padding: .2rem .5rem;
      background-color: #eef6ff;
      border-radius: .425rem;
      border: 1px solid $primary;
      box-sizing: border-box;
      white-space: nowrap;
      margin: .125rem 0;
    }

    .label {
      color: $text-primary;
      font-size: .85rem;
      font-weight: 500;
    }

    .value {
      font-size: .85rem;
      color: $text-primary-lighter;
      padding: 0 .15rem;
    }

    .noData {
      font-size: .81em;
      color: $text-primary-lighter;
      font-style: italic;
    }

    .tooltipInnerWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: .5rem;
      padding-bottom: .2rem;
    }

    .chart {
      padding: .5rem .75rem .2rem .75rem;
      box-sizing: border-box;
      width: 100%;
      height: 8rem;
    }

    .button {
      font-size: 1rem;
      padding: .25rem;
      stroke: none;
      color: $primary;
    }
  }
}

@import "global.scss";
.navbar {
  background: $uob_white;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  min-width: 12rem;

  .groupHeader {
    padding: .5em 1em;
    font-weight: 500;
    background: #d4d4d4;
    color: #444;
  }

  hr {
    width: 100%;
    border: $border;
    border-width: 1px 0 0 0;
    margin: 0;
  }
}

.itemsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em 0;
}

.fillMax {
  width: 100%;
  flex-grow: 1;
}

.version {
  color: $text-primary-lighter;
  padding: 1em;
}

@import "global.scss";
.pastCommitments {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: white;
  border: $border;
  border-radius: .5rem;
  margin: 1rem 1rem 0 1rem;
  box-sizing: border-box;

  .pastCommitmentsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    &.withBorder {
      border-bottom: $border;
    }

    h4 {
      font-size: 1.1rem;
      font-weight: 500;
      color: $text-primary;
      padding: 1rem;
    }

    .pastCommitmentsHeaderControls {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: .5rem;
      color: $text-primary-lighter;
      padding-right: .2rem;

      p {
        font-size: 1rem;

        &.noCommitments {
          padding-right: 1rem;
        }
      }

      .dropdownButton {
        transition: .2s ease-in-out;
        transform: rotate(0deg);

        &.isOpen {
          & > svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .list {
    box-sizing: border-box;
    //width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    margin: 0 1rem;

    & > :first-child {
      margin-top: 1rem;
    }

    & > :last-child {
      margin-bottom: 1rem;
    }
  }
}

@import "global.scss";
.header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 1.5rem;

  h1 {
    color: $text-primary;
    font-size: 1.5em;
    font-weight: 500;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
  }

  .stack {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
    justify-content: center;
  }

  .rightStack {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    justify-content: center;
  }
}

@import "global.scss";
.instructionsSectionImage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  margin: 1.5em 0;
  box-sizing: border-box;

  img {
    display: block;
    margin: .5em auto;
    max-width: 100%;
    border: $border;
    border-radius: $border-radius;
  }

  p {
    width: 70%;
    text-align: center;
    color: $text-primary-lighter;
    margin: .75em auto 0 auto;
  }
}



@media print {
  .instructionsSectionImage {
    break-inside: avoid;
  }
}

@import "global.scss";
.connectionIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //padding: .25em 1em;
  gap: .5em;
  border-radius: .25rem;
  //border: $border;
  //background-color: #f9f9f9;


  .dot {
    width: .5em;
    height: .5em;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    border-radius: 1em;
    position: relative;

    &.connecting {
      background-color: #ffb867;
      animation-name: blinking;
    }
  
    &.connected {
      background-color: #39b750;
    }
  
    &.disconnecting {
      background-color: #ffb867;
      animation-name: blinking;
    }
  
    &.disconnected {
      background-color: #d34747;
    }
  
    &.uninstantiated {
      background-color: #aaaaaa;
    }

    &.updating {
      background-color: #39b750;
      animation-name: blinking;
    }
  }

  .label {
    font-size: .8em;
    color: $text-primary;
    padding: .2rem 0;
    font-weight: 500;
    text-transform: uppercase;
  }

  .reconnectIndicator {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: #4778d3;
    font-size: .8em;
    font-weight: 600;
    margin-left: .5rem;

    .icon {
      height: 1rem;
      font-size: 1rem;
      position: relative;
      bottom: .25rem;
    }
  }

  .meetingLockIndicator {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: #d34747;
    font-size: .8em;
    font-weight: 600;
    margin-left: .5rem;

    .icon {
      font-size: 1rem;
      position: relative;
      top: .05rem;
    }
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
    //top: 0;
  }

  50% {
    opacity: .5;
    //top: 1em;
  }

  100% {
    opacity: 1;
    //top: 0;
  }
}

@import "global.scss";
.goal {
  .header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 1.5rem;

    h1 {
      color: $text-primary;
      font-size: 1.5em;
      font-weight: 500;
    }

    .row {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .mainRow {
    width: 100%;
    box-sizing: border-box;
    padding: .5rem 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    .stack {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-bottom: 1rem;

      &:nth-child(1) {
        box-sizing: border-box;
        padding-right: 2em;
        flex-basis: 30%;

        .description {
          font-size: 1em;
          color: $text-primary-lighter;
        }

      }

      &:nth-child(2) {
        box-sizing: border-box;
        padding-right: 2em;
        flex-basis: 40%;

        .value {
          font-size: 1rem;
          color: $text-primary-lighter;
        }
      }

      &:nth-child(3) {
        box-sizing: border-box;
        flex-basis: 30%;

        .label:not(:first-child) {
          padding-top: .5rem;
        }

        .teams {
          font-size: 1rem;
          color: $text-primary-lighter;
          font-weight: 500;

          .teamName {
            color: $primary-lighter;
            font-weight: 500;
          }
        }

        .row:has(.teams) a {
          margin-top: -.5rem;
        }

        .value {
          color: $text-primary;
          font-weight: 500;
          padding-right: .5em;
        }

        .valueSecondary{
          padding-left: .5em;
          font-weight: 300;
        }

        button {
          margin-top: auto;
        }
      }

      & > * {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: .5rem;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .label  {
        color: $text-primary;
        font-size: 1em;
        font-weight: 500;
      }

      .value {
        font-size: 1rem;
        color: $text-primary;
      }

      .valueSecondary {
        font-size: 1rem;
        color: $text-primary-lighter;
      }

      .noData {
        font-size: 1rem;

        color: $text-primary-lighter;
        font-style: italic;
      }
    }
  }

  .chart {
    padding: 2rem 0;
    width: 100%;
    height: auto;
    aspect-ratio: 3;
  }
}

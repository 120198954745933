@import "global.scss";
.signIn {
  width: 20em;
  margin: 2em auto;
  padding: 2em;
  border: $border;
  border-radius: $border-radius;

  form {
    margin-top: 1em;
  }

  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.5em;
    color: $text-primary;
  }


  .description {
    line-height: 1.3;
    margin-bottom: 2em;

    a {
      color: $primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .formControls {
    margin: 1em 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .alert {
    margin-top: 1em;
  }
}

@import "global.scss";
.prepareCommitmentForm {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 0 0;

  .stack {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: .5rem;

    div {
      margin: 0 !important;
    }
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    gap: 1rem;

    div {
      flex-grow: 1;
      margin: 0 !important;
    }

    .icon {
      color: $text-primary-lighter;
      font-size: 1.2rem;
      position: relative;
      top: 1.1rem;
    }
  }
}

@import "global.scss";
.goalHierarchyForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  gap: 1rem;
}

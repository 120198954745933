@import "global.scss";
.progressBar {
  width: 100%;

  .outer {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, .1);
    border: $border;
    border-radius: 1em;
    background-color: white;
    width: 100%;
    height: .8em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .inner {
    background-color: $primary;
    border-radius: 1em;
    height: .425em;
    margin: 0 0.125em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    min-width: .425em;

    .trailingDot {
      background-color: color(from $primary srgb r g b / 0.5);
      border-radius: 1em;
      height: .425em;
      width: .425em;
    }
  }
}

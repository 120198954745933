@import "global.scss";
.meetingCommitments {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  h2 {
    font-size: 1.25rem;
    color: $text-primary-darker;
    margin: 1rem 0;
    font-weight: 500;
  }

  .user {
    margin-top: 2rem;
    background-color: #fafafa;
    border-radius: .5rem;
    border: $border;
    overflow: hidden;


    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      box-sizing: border-box;
      padding: 1rem 1.5rem;
      border-bottom: $border;
      background-color: white;
      gap: 1rem;

      h3 {
        font-weight: 500;
        font-size: 1.2rem;
        color: $text-primary;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }

    .commitments {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      margin: 0 1rem 1rem 1rem;

      button {
        background-color: #ffffff;
      }
    }
  }
}
